import { TeamsFxContext } from "./Context";
import { useContext, useState, useEffect } from "react";
import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "fbc44597-0718-431f-993d-76168e7805ca",
    authority: "https://login.microsoftonline.com/common/",
    redirectUri:
      window.location.origin +
      "/auth-end.html?clientId=fbc44597-0718-431f-993d-76168e7805ca",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const Login = () => {
  const { teamsUserCredential } = useContext(TeamsFxContext);

  useEffect(() => {
    msalInstance.initialize().catch((error) => {
      console.error("MSAL initialization error:", error);
    });
  }, []);

  const openPopup = () => {
    const loginRequest = {
      scopes: ["User.Read", "Contacts.Read", "People.Read"],
      prompt: "consent",
    };
    msalInstance.loginRedirect(loginRequest);
  };

  return (
    <div className="content_bg text-center">
      <button className="mt-5 btn submit-btn reload ms-2" onClick={openPopup}>
        Authorize with Microsoft
      </button>
      <div className="mt-3 text-white">
        Sign in with Microsoft and Authorize Now
      </div>
    </div>
  );
};

export default Login;
